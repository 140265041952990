import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledP,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from './button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledTileContainer = styled(StyledColumnContainer)`
  width: auto;
  display: grid;
  margin: 5px;
  justify-items: start;
  align-items: start;
  grid-template-rows: auto 20px auto;
  ${media.greaterThan('small')`
    grid-template-rows: 200px 20px auto;
    width: 400px;
  `}
`

const StyledTilesContainer = styled(StyledColumnsContainer)`
  justify-content: flex-start;
  margin: 0 -20px;
  padding: 0;
`

const StyledImageContainer = styled(Box)`
  width: 240px;
  height: auto;
  ${media.greaterThan('small')`
    height: 200px;
    width: 400px;
  `}
`

const StyledDate = styled(StyledP)`
  margin-top: 15px;
  font-size: 16px;
  opacity: 0.7;
`
const BlogTiles = ({ data, shade }) => {
  return (
    <StyledContainer componentName="BlogTiles" shade={shade}>
      <StyledTilesContainer columns={3}>
        {data.allPrismicBlogPost && data.allPrismicBlogPost.edges.map(({ node }, index) => {
          return (
            node.data.hero.localFile && (
              <StyledTileContainer align="center" key={shortid.generate()} width={"100%"}>
                <a href={`/${node.lang}/blog/${node.uid}`}>
                  <StyledImageContainer
                    justify="center"
                    round="1px"
                    background={
                      shade === 'light'
                        ? 'primaryGrey'
                        : shade === 'grey'
                        ? 'primaryWhite'
                        : 'primaryDark'
                    }
                  >
                    <Img
                      alt="hero image for post"
                      imgStyle={{ objectFit: 'contain' }}
                      fluid={node.data.hero.localFile.childImageSharp.fluid}
                    />
                  </StyledImageContainer>
                </a>

                <StyledDate>{node.data.published_date}</StyledDate>
                <StyledP>{node.data.title.text}</StyledP>
              </StyledTileContainer>
            )
          )
        })}
      </StyledTilesContainer>
    </StyledContainer>
  )
}

BlogTiles.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(BlogTiles)
