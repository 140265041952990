import { Link, withIntl } from '../../i18n'
import { bannerIcon, fluidImage } from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import BlogTiles from '../../components/reusables/blogTiles'
import { Box } from 'grommet'
import { FormattedMessage } from 'react-intl'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import React from 'react'
import { graphql } from 'gatsby'

const Blog = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <BlogTiles data={data} shade={'light'} />
  </Layout>
)

export default withIntl(Blog)

export const blogQuery = graphql`
  query blogPosts($locale: String!) {
    allPrismicBlogPost(
      filter: { lang: { eq: $locale } }
      sort: { fields: [data___published_date], order: DESC }
    ) {
      edges {
        node {
          lang
          uid
          alternate_languages {
            uid
            lang
            type
          }
          data {
            title {
              text
            }
            published_date
            description {
              text
            }
            author {
              text
            }
            hero {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            content {
              html
            }
          }
        }
      }
    }
    bannerGlobe: file(relativePath: { eq: "hero/bannerGlobe@3x.png" }) {
      ...bannerIcon
    }
    shortLastSection: file(
      relativePath: { eq: "hero/shortLastSection@2x.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
